import http from '@/utils/request'
import BIHttp from '@/utils/BI-request'
//获取流程页面
export const bpmFormView = params => {
  return http({
    url: '/commonservice/bpm/formView',
    method: 'GET',
    params
  })
}

//发起流程
export const dealerFlowTestApiSubmit = data => {
  return http({
    url: '/dealermanage/dealerFlow/dealerFlowTestApi/submit',
    method: 'POST',
    data
  })
}

//获取数据
export const dealerFlowTestApiGetFlowData = params => {
  return http({
    url: '/dealermanage/dealerFlow/dealerFlowTestApi/getFlowData',
    method: 'GET',
    params
  })
}

//流程字段获取权限
export const queryColumnAuthority = params => {
  return http({
    url: '/commonservice/bpm/queryColumnAuthority',
    method: 'GET',
    params
  })
}

//审批流程
export const dealerFlowTestApiExecute = data => {
  return http({
    url: '/dealermanage/dealerFlow/dealerFlowTestApi/execute',
    method: 'POST',
    data
  })
}

/**
 * @description: bpm 回调审批保单
 * @param {string} data.fdFormInstanceId 报表id
 * @param {string} data.fdFormTemplateCode 报表模板 code
 * @param {number} data.status 0 驳回草稿(起草人撤回) 1 流程数据处理-流程归档 2 流程废弃 3 流程强制归档
 * @see http://10.12.0.64/docs/operate/operate-1f826j6q95622
 */
export const bpmCallbackApi = data => {
  return http({
    url: '/commonservice/bpm/updateFlowStatus',
    method: 'POST',
    data
  })
}
/**
 * @description:根据送达方获取近三年提货情况
 * @param {string} data.SHIP_TO_PARTY_CODE
 * @return {*}
 */
export const requestGetPickUpByAuthorizedBrand = data => {
  return http({
    url: '/commonservice/biService/postData',
    method: 'POST',
    data: {
      requestUrl: '/api/get_achievement_stat_by_ship',
      jsonData: {
        ...data
      }
    }
  })
}
