/*
 * @Author: wangmq
 * @Date: 2023-09-01 09:23:30
 * @LastEditors: wangmq
 * @LastEditTime: 2023-09-05 14:42:40
 */
import axios from 'axios'
import { getStorage, setStorage, removeStorage } from '@/utils/storage'
import { Message } from 'element-ui'
const service = axios.create({
  baseURL: process.env.VUE_APP_BI_URL,
  timeout: 1000 * 10 * 6 // request timeout
})

service.interceptors.request.use(
  async config => {
    const token = await getBIToken()
    config.headers = {
      ...config.headers,
      Authorization: token
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data
    if (!res.success) {
      return Promise.reject(response)
    }
    return res
  },
  async error => {
    console.log(error) // for debug
    const status = error.response.status
    if (status === 401) {
      const BI_token = getStorage('BI_token')
      BI_token && removeStorage('BI_token')
      await getBIToken()
      return service.request(error.config)
    }else {
      Message({
        message: error || 'Error',
        type: 'error',
        duration: 3 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service

const requestFreshToken = axios.create()
export const getBIToken = async () => {
  const token = getStorage('BI_token') || ''
  if (token) {
    return token
  }
  // 当 token 不存在时，重新获取 token
  const { data } = await requestFreshToken.get(
    process.env.VUE_APP_BI_URL +
      '/token/generate?appid=HFMVnCNDAEK5fWWa&secret=m9yC4d4di4w5jTQbE6YJHjNg1a3X2vtN',
  )
  setStorage('BI_token', data.token)
  return data.token
}

