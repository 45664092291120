<template>
  <div>
    <div ref="form">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item
          v-for="item in formInlineData"
          :key="item.key"
          :label="`${item.label}：`">
          <request-module-select
            v-if="item.select"
            v-model="formInline[item.key]"
            :filterable="true"
            :placeholder="item.label"
            :type="item.model" />
          <el-input
            v-else
            v-model="formInline[item.key]"
            clearable
            :placeholder="`请输入${item.label}`"
            @keyup.enter.native="query"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
          <el-button @click="hanldeDowloadTemplate">下载模板</el-button>
          <el-button @click="() => $refs.fileRef.click()">
            <input
              id="file"
              ref="fileRef"
              @change="handleImportChange"
              hidden
              type="file"
              name="file"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />导入
          </el-button>
          <el-button @click="exportFile">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div :style="{ height: `calc(100vh - ${withoutTableHeight}px)` }">
      <el-table
        ref="table"
        border
        :data="dataList"
        style="width: 100%"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        height="100%"
        v-loading="loading"
        :show-overflow-tooltip="true">
        <el-table-column
          prop="dealerCode"
          label="经销商编号"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="dealerName"
          label="经销商名称"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="newOrTransfer"
          label="空白/转让加盟"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="ifEnabled"
          label="经销商状态"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.ifEnabled ? '停用' : '启用' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="jdDealerStatus" label="经典授权状态">
          <template slot-scope="scope">
            <div>
              {{ brandStatus(scope.row.jdDealerStatus) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="abandoningType" label="淘汰状态">
          <template slot-scope="scope">
            <div>
              {{ abandoningType(scope.row.abandoningType) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="abandoningTime" label="淘汰时间" />
        <el-table-column prop="abandoningExcuse" label="淘汰原因" />
        <el-table-column prop="newOrTransfer" label="操作人" />

        <el-table-column label="操作" width="110" fixed="right">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="editDealer(scope.row)"
              >修改</el-link
            >
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="primary"
              :underline="false"
              @click="handleShowRecordDrawer(scope.row)"
              >变更记录</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="limit"
        :current-page="page"
        @current-change="currentChange"
        @size-change="handleSizeChange" />
    </div>
    <dealerDrawer
      :selectItem="selectItem"
      :updateDetailData="updateDetailData"
      :isOnlyShow="isOnlyShow"
      :datahistorydetail="datahistorydetail"
      @closeVisibleDialog="isOnlyShow = false"
      @getDataList="getDataList"
      ref="dealerDrawer" />
    <dealerRecordDrawer
      :selectItem="recordSelectItem"
      @recordDetail="handleRecordDetail"
      ref="recordDrawer" />
  </div>
</template>

<script>
import {
  abandoningpage,
  distributorImportDownload,
  distributorImport,
  listpageExporttask
} from '@/api/businessOos'
import { getStorage } from '@/utils/storage'
import { tableHeight } from '@/mixin/tableHeight'
import dealerRecordDrawer from './components/dealerRecordDrawer'
import dealerDrawer from './components/dealerDrawer'
import { uploadFile } from '@/utils/obsUpload'
import throttle from 'lodash/throttle'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect.vue'
import store from '@/store'

const formInlineData = [
  {
    label: '经销商编号',
    key: 'dealerCode'
  },
  {
    label: '经销商名称',
    key: 'dealerName'
  },
  {
    label: '经典授权状态',
    key: 'jdDealerStatus',
    select: true,
    model: 'brand_status'
  },
  {
    label: '淘汰状态',
    key: 'abandoningType',
    select: true,
    model: 'abandoning_type'
  }
]
export default {
  components: {
    dealerDrawer,
    dealerRecordDrawer,
    RequestModuleSelect
  },
  mixins: [tableHeight],
  inject: ['addTask'],
  data() {
    return {
      formInline: {}, // 搜索条件
      formInlineData,
      dataList: [],
      total: 0,
      limit: 10,
      page: 1,
      dialogVisible: false,
      title: '',
      type: '',
      tableList: [],
      id: '',
      status: 0,
      loading: false,
      tabsType: 'old',
      selection: null,
      userInfo: {},
      authorizedCityName: '',
      fullscreenLoading: false,
      userInfo: getStorage('userInfo') || {},
      selectItem: {},
      recordSelectItem: {},
      updateDetailData: {},
      isOnlyShow: false,
      datahistorydetail: {}
    }
  },
  computed: {
    brandStatus() {
      return val => {
        return store.getters.dictionary.brand_status.find(
          item => item.code == val
        )?.content
      }
    },
    abandoningType() {
      return val => {
        return store.getters.dictionary.abandoning_type.find(
          item => item.code == val
        )?.content
      }
    }
  },
  watch: {
    tabsType(val) {
      this.getDataList()
    }
  },
  methods: {
    handleRecordDetail(row) {
      this.recordSelectItem = row
      this.$refs.recordDrawer.openDialog()
    }
  },
  created() {
    this.userInfo = getStorage('userInfo')
    this.getDataList()
  },
  mounted() {
    // const formH = this.$refs.form.offsetHeight
    // const topH = document.querySelector('#topBar').offsetHeight
    // this.withoutTableHeight = formH + topH + 100
  },
  methods: {
    exportFile() {
      this.addTask(
        '经销商操作日志导出',
        () => {
          return listpageExporttask({
            userId: this.userInfo.accountId,
            userAccount: this.userInfo.account,
            moduleName: '经销商操作日志导出',
            tableName: 'dealer_crm_distributor'
          })
        },
        '1'
      )
    },
    async handleImportChange(e) {
      //导入excel表格
      const { res } = await uploadFile(e.target.files[0])
      //导入excel表格
      const form = new FormData()
      form.append('multipartFile', e.target.files[0])
      form.append('updateBy', this.userInfo.accountId)
      form.append('updateUserName', this.userInfo.realName)
      form.append('fileUrl', res.requestUrls[0])

      distributorImport(form).then(res => {
        res?.msg && this.$message.success(res.data || res.msg)
        this.getDataList()
      })
      e.target.value = ''
    },
    hanldeDowloadTemplate: throttle(() => distributorImportDownload({}), 500),
    handleRecordDetail(item) {
      this.isOnlyShow = true
      this.$refs.dealerDrawer.storeVisibleDialog = true
      this.selectItem = JSON.parse(item.historicalData)
      this.selectItem.updateRemark = item.remark
      this.updateDetailData = JSON.parse(item.afterInfo)
      this.datahistorydetail = item
    },
    handleShowRecordDrawer(item) {
      this.isOnlyShow = false

      this.recordSelectItem = item
      this.$refs.recordDrawer.showRecordDrawer = true
    },
    editDealer(item) {
      this.dialogVisible = true
      this.title = '修改经销商'
      this.type = 'editDealer'
      this.selectItem = item
      this.$refs.dealerDrawer.storeVisibleDialog = true
    },
    query() {
      // console.log(this.formInline);
      this.page = 1
      this.getDataList()
    },
    async getDataList() {
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollTop = 0
      })
      this.loading = true
      const res = await abandoningpage({
        ...this.formInline,
        currPage: this.page,
        pageSize: this.limit
      })
      this.loading = false
      // console.log(res);
      if (res.code === 0) {
        this.dataList = res.data
        this.total = res.totalCount
      }
    },
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getDataList()
    },
    // 查看
    showDialog(title, type, data) {
      this.dialogVisible = true
      this.title = title
      this.type = type
      this.id = data.businessLicenseId
      this.authorizedCityName = data.authorizedCityName
      switch (type) {
        case 'brand':
          //查看授权品牌
          this.getBossCorrelationInfo({
            crmDistributorId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'party'
          })
          break
        case 'shop':
          //查看授权门店
          this.getBossCorrelationInfo({
            crmDistributorId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'shop'
          })
          break
        case 'boss':
          //查看送达方
          this.getBossCorrelationInfo({
            crmDistributorId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'authorized_brand'
          })
          break
        case 'authorzed':
          //查看售达方
          this.getBossCorrelationInfo({
            crmDistributorId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'license'
          })
          break
        case 'relevant':
          this.getRelevantList(data)
          break
      }
    },

    // 获取相关流程
    getRelevantList(data) {
      console.log(data)
    },
    handleChange(id, type) {
      const data = this.dataList.find(item => item.businessLicenseId == id)
      this.getBossList(data, type)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixin';
@import '@/styles/colors';

:deep(.el-form) {
  .el-input__inner {
    width: 250px;
  }
}
::v-deep .el-table__fixed-header-wrapper .el-checkbox {
  display: none;
}
.paginationBar {
  @include flex(center, center);
  width: 100%;
  height: 60px;
  overflow-x: auto;
}

.handle {
  color: $primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.shopTypeSelect {
  ::v-deep {
    .el-input.is-disabled,
    .el-input {
      .el-input__inner {
        border: none;
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 35px;
      }
    }

    .el-input__icon {
      cursor: default;
    }
  }
}
</style>
