/*
 * @Author: wangmq
 * @Date: 2023-07-25 18:02:33
 * @LastEditors: wangmq
 * @LastEditTime: 2023-07-26 09:45:17
 */
import http, { download } from '@/utils/request'

export const getHandleList = params => {
  return http({
    url: '/dealermanage/datadealwithlog/listpage',
    method: 'get',
    params
  })
}
/**
 * @description: 获取下载模板
 */
export const requestGetModule = () => {
  download('dealermanage/datadealwith/closeDeliveryPartyExcel')
}

/**
 * @description: 提交数据
 * @param {*} data
 */

export const requestSubmit = data => {
  return http({
    url: '/dealermanage/datadealwith/closeDeliveryPartyImport',
    method: 'post',
    data
  })
}

export const datahistorydetailListpage = params => {
  return http({
    url: '/dealermanage/datahistorydetail/listpage',
    method: 'get',
    params
  })
}

export const shopUpdateShopLog = data => {
  return http({
    url: '/dealermanage/shop/updateShopLog',
    method: 'post',
    data
  })
}

export const crmdistributorUpdate = data => {
  return http({
    url: '/dealermanage/crmdistributor/updateAbandoningJD',
    method: 'post',
    data
  })
}
