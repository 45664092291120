import http from '@/utils/request'
// 保存草稿
export const saveDraft = data =>
  http({
    url: '/dealermanage/bpm/jdVMDbzws/saveDraft',
    method: 'POST',
    data
  })
//获取详情
export const getDetail = params =>
  http({ url: '/dealermanage/bpm/jdVMDbzws/details', params })
//提交
export const submit = data =>
  http({
    url: '/dealermanage/bpm/jdVMDbzws/submit',
    method: 'POST',
    data
  })
//审批
export const submitApproveData = data =>
  http({
    url: '/dealermanage/bpm/jdVMDbzws/submitApproveData',
    method: 'POST',
    data
  })
